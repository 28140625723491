import { ref } from 'vue';

import Axios, { AxiosError, AxiosResponse } from 'axios';
import { toastController } from '@ionic/vue';
import useLocalStorage from '@/composables/useLocalStorage';
const cachedResponses = {} as any;
const doNotCacheUrls = ['/me'];
const lastError = ref(null) as any;
const lastErrorCode = ref<number | null>(null);
const lastErrorStatus = ref<string | null>(null);
const lastResponse = ref(null) as any;
const pending = ref(false);

export default function useApi() {
  const apiGet = async<T>(url: string, additionalHeaders? : null | object, fromCache = false, useBaseUrl = false, staleTimer?: number) : Promise<T> => {
    return req<T>(url, 'GET', null, additionalHeaders, fromCache, useBaseUrl, staleTimer);
  };

  const apiPost = async<T>(url: string, body?: null | object, useBaseUrl = false, additionalHeaders? : null | object, fromCache = false) : Promise<T> => {
    return req<T>(url, 'POST', body, additionalHeaders, fromCache, useBaseUrl);
  };

  const apiPut = async<T>(url: string, body?: null | object, additionalHeaders? : null | object, fromCache = false) : Promise<T> => {
    return req<T>(url, 'PUT', body, additionalHeaders, fromCache);
  };

  const apiDelete = async<T>(url: string, body?: null | object, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'DELETE', body, additionalHeaders, false);
  };

  const req = async<T>(url: string, method: string, body?: null | object, additionalHeaders? : null | object, fromCache = false, useBaseUrl = false, staleTimer?: number) : Promise<AxiosResponse<T> | any> => {
    const storage = await useLocalStorage();
    const accountToken = await storage.get('accountToken');
    pending.value = true;
    const cacheKey = url + (body ? JSON.stringify(body) : '');
    if (fromCache && cachedResponses[cacheKey]?.data && (staleTimer ? cachedResponses[cacheKey]?.timestamp + staleTimer >= new Date().getTime() : true)) {
      pending.value = false;

      return cachedResponses[cacheKey].data as T;
    }
    lastResponse.value = null;
    lastError.value = null;
    lastErrorCode.value = null;

    try {
      const opts = {
        headers: {
          'Accept': 'application/json',
        },
        baseURL: useBaseUrl ? import.meta.env.VITE_BASE_URL : import.meta.env.VITE_API_URL,
        method: method,
      } as any;

      if (accountToken) {
        opts.headers.Authorization = `Bearer ${accountToken}`;
      }

      if (body) {
        opts.data = body;
      }

      if (additionalHeaders) {
        console.log(additionalHeaders)
        opts.headers = Object.assign(opts.headers, additionalHeaders);
      }

      const res = await Axios<T>({
        url,
        ...opts,
      });

      if (!doNotCacheUrls.some(doNotCache => url.includes(doNotCache))) {
        cachedResponses[cacheKey] = {
          data: res.data,
          timestamp: new Date().getTime(),
        };
      }

      lastResponse.value = res.data;

      return res.data as T;

    } catch (e: any) {
      console.log(e);
      const error = e as AxiosError<{ error: string, message: string }>;
      lastError.value = error?.response?.data.message;
      lastErrorStatus.value = error?.response?.data.error;
      lastErrorCode.value = error?.response?.status || 0;
      return null;
    } finally {
      pending.value = false;
    }
  };

  const handleApiError = async(
    header = 'Någonting gick fel',
    message = 'Försök igen eller kontakta support'
  ) => {
    console.error(lastError.value);
    console.warn('Code: ' + lastErrorCode.value);
    let toastHeader = header;
    let toastMessage = message;
    let color = 'danger';

    if (lastErrorCode.value === 401) {
      toastHeader = '';
      toastMessage = 'Du har inte behörighet att göra detta. Om du tror att detta är ett fel, kontakta administratör';
      color = 'warning';
    } else if (lastErrorCode.value === 400 && lastErrorStatus.value === 'invalid_grant') {
      toastHeader = '';
      toastMessage = 'Felaktiga inloggningsuppgifter, kontrollera att du har angett rätt e-postadress och lösenord';
      color = 'warning';
    }

    const toast = await toastController.create({
      header: toastHeader,
      message: toastMessage,
      duration: 5000,
      position: 'bottom',
      color: color,
      swipeGesture: "vertical",
    });

    await toast.present();
  };

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
    lastError,
    lastErrorCode,
    lastResponse,
    pending,
    handleApiError,
  };
}
