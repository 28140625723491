import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import MainTabs from '../views/Main.vue'
import Auth from '../views/Auth.vue'
import { useUserStore } from "@/stores/user";
import useLocalStorage from "@/composables/useLocalStorage";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/',
    meta: { openPage: true },
  },
  {
    path: '/auth/',
    component: Auth,
    meta: { openPage: true },
  },
  {
    path: '/tabs/',
    component: MainTabs,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'workorders',
        component: () => import('@/views/WorkOrders.vue')
      },
      {
        path: 'workorders/:id',
        component: () => import('@/views/workorders/WorkOrder.vue')
      },
      {
        path: 'my-workorders',
        component: () => import('@/views/MyWorkOrders.vue')
      },
      {
        path: 'my-workorders/:id',
        component: () => import('@/views/my-workorders/MyWorkOrder.vue')
      },
      {
        path: 'messages',
        component: () => import('@/views/messages/Messages.vue'),
      },
      {
        path: 'messages/:id',
        component: () => import('@/views/messages/Message.vue'),
      },
      {
        path: 'profile',
        component: () => import('@/views/Profile.vue')
      },
      {
        path: 'profile/payslips',
        component: () => import('@/views/profile/Payslips.vue')
      },
      {
        path: 'profile/offices',
        component: () => import('@/views/profile/Offices.vue')
      },
      {
        path: 'profile/informationPages',
        component: () => import('@/views/profile/InformationPages.vue')
      },
      {
        path: 'profile/settings',
        component: () => import('@/views/profile/Settings.vue')
      },
      {
        path: 'profile/notifications',
        component: () => import('@/views/profile/NotificationSettings.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => !record.meta.openPage)) {
    const storage = await useLocalStorage();
    const accessToken = await storage.get('accountToken');
    const accessTokenExpiresAt = await storage.get('accountTokenExpiresAt');
    console.log('ACCESS TOKEN', accessToken, accessTokenExpiresAt)
    if (accessToken && new Date() < new Date(accessTokenExpiresAt)) {
      next();
    } else {
      next('/auth/');
    }
  } else {
    next();
  }
});

export default router
