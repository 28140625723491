import ResourceTypes from "@/constants/resourceTypes";
import {alertController, isPlatform, useIonRouter} from "@ionic/vue";
import {DeliveredNotifications, PushNotifications, PushNotificationSchema} from "@capacitor/push-notifications";
import {APIActionPerformed, APINotification} from "@/constants/pushNotificationTypes";
import {useUserStore} from "@/stores/user";
import useApi from "@/composables/useApi";

export default async function useNotificationCentre() {
  const router = useIonRouter();
  const userStore = useUserStore();
  const handleNotification = async(notification: APINotification, isInApp = false) => {
    let header = notification.title;
    let message = '';
    let routeString = '';
    const data = notification.data;
    if (data) {
      switch (data.resource_type) {
        case ResourceTypes.Message:
          message = 'Vill du gå till meddelandet?';
          routeString = `/tabs/messages/${data.resource_id}`;
          break;
        case ResourceTypes.Job:
          message = 'Vill du gå till uppdraget?';
          routeString = `/tabs/my-workorders`;
          break;
        case ResourceTypes.Payslip:
          message = 'Vill du gå till lönespecifikationen?';
          routeString = `/tabs/profile/payslips`;
          break;
        case ResourceTypes.WorkOrder:
          message = 'Vill du se de nya uppdragen?';
          routeString = `/tabs/workorders`;
          break;
      }

      if (isInApp) {
        const alert = await alertController.create({
          header,
          message,
          buttons: [
            {
              text: 'Nej',
              role: 'cancel',
              cssClass: 'secondary',
              handler: async () => {
                await userStore.getPushNotifications();
              },
            },
            {
              text: 'Ja',
              handler: async () => {
                await clearNotification(notification);
                router.push(routeString);
              },
            },
          ],
        });

        await alert.present();
      } else {
        await clearNotification(notification);

        if (routeString) router.push(routeString);
      }
    }
  };

  const clearNotification = async(notification: APINotification) => {
    const {apiPost} = useApi();
    if (isPlatform('ios')) {
      await PushNotifications.removeDeliveredNotifications({ notifications: [notification] });
    } else {
      await PushNotifications.removeAllDeliveredNotifications();
    }
    await apiPost(`/v2/push/${notification.data.notification_id}/markasread`);
    await userStore.getPushNotifications();
  }

  return {
    handleNotification,
  };
}
