import { defineStore } from 'pinia';
import useApi from "@/composables/useApi";
import qs from 'qs';

export const useMessageStore = defineStore('messageStore', {
  state: () => ({
    messages: [] as Message[],
    currentPage: 1,
    totalPages: 1,
    loading: true,
  }),
  actions: {
    async fetchMessages(page = 1) {
      const { apiGet } = useApi();
      this.loading = true;
      this.error = null;
      const params = {
        page: page || 1,
        per_page: 12,
      };

      const query = qs.stringify(params);

      const res = await apiGet<MessageResponse>(`/messages/me?${query}`);
      if (page === 1) {
        this.messages = res.result.data;
      } else {
        this.messages = [...this.messages, ...res.result.data];
      }
      this.currentPage = res.result.current_page;
      this.totalPages = res.result.last_page;
      this.loading = false;
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        await this.fetchMessages(this.currentPage + 1);
      }
    },
    async prevPage() {
      if (this.currentPage > 1) {
        await this.fetchMessages(this.currentPage - 1);
      }
    },
    async updateMessageAttendance(messageId: string, attending: boolean, message: string) {
      const { apiPut, handleApiError } = useApi();
      const params = {
        employee_attend: attending,
        employee_message: message,
      };

      const res = await apiPut<MessageResponse>(`/messages/me/${messageId}`, params);

      if (res) {
        this.messages = this.messages.map((m) => {
          if (m.id === Number(messageId)) {
            return res.result;
          }
          return m;
        });
      } else {
        await handleApiError();
      }
    },
  },
  getters: {
    lastMessage() {
      return this.messages[0];
    },
  }
});
