import { defineStore } from 'pinia';
import { isPlatform } from '@ionic/vue';
import useApi from "@/composables/useApi";
import qs from 'qs';
import {useUserStore} from "@/stores/user";
import {Browser} from "@capacitor/browser";
import { AppLauncher } from '@capacitor/app-launcher';

export const useBankIdStore = defineStore('bankIdStore', {
  state: () => ({
    qrCode: null as string | null,
    bankIdMessage: null as string | null,
    orderRef: null as string | null,
    bankIdFailed: false,
    collecting: false,
    loggingIn: false,
  }),
  actions: {
    async startBankIdAuth(testMode?: boolean) {
      const { apiGet } = useApi();
      const isDesktop = !isPlatform('ios') && !isPlatform('android');
      let params;
      this.bankIdFailed = false;
      this.collecting = true;

      if (testMode) {
        params = {
          endUserIp: '89.255.243.132',
          personalNumber: '198001016636',
        };
      } else if (isDesktop) {
        params = {
          bankIdMessage: 'VmV0ZXJhbnBvb2xlbiBpbmxvZ2duaW5n',
        };
      }

      const query = qs.stringify(params)

      const loginRes = await apiGet<{ result: { autoStartToken: string; orderRef: string } }>(`/auth/login?${query}`);

      if (loginRes && loginRes.result) {
        this.orderRef = loginRes.result.orderRef;
        if (isDesktop) {
          await this.collectBankId(isDesktop);
        } else {
          try {
            await AppLauncher.openUrl({ url: `bankid:///?autostarttoken=${ loginRes.result.autoStartToken }&redirect=vappen://bankid-callback` });
            await this.collectBankId(isDesktop);
          } catch (e) {
            this.bankIdMessage = 'Kunde inte starta BankID-appen, vänligen bekräfta att du har BankID-appen installerad och försök igen.';
          }
        }
      }
    },
    async collectBankId(isDesktop = false) {
      const { apiGet } = useApi();
      const { login } = useUserStore();
      this.loggingIn = false;
      const res = await apiGet<{ result: {
        qrData: string;
        message: string;
        status: string;
        access_token: string;
        access_token_expires_at: number;
      } }>(`/auth/collect-qr?orderRef=${this.orderRef}`, { version: 2 });
      if (res) {
        console.log(res.result)
        if (isDesktop) {
          this.qrCode = res.result.qrData;
        }
        this.bankIdMessage = res.result.message;

        if (res.result.status === 'pending') {
          setTimeout(() => this.collectBankId(isDesktop), 1000);
        } else if (res.result.status === 'failed') {
          this.bankIdFailed = true;
          this.collecting = false;
        } else {
          this.collecting = false;
          this.loggingIn = true;
          await login({
            accessToken: res.result.access_token,
            accessTokenExpiresAt: res.result.access_token_expires_at,
          })
        }
      }
    }
  }
});
