import { Storage } from '@ionic/storage';
export default async function useLocalStorage() {
  const storage = new Storage();
  await storage.create();

  const set = async(key, value) => {
    return await storage.set(key, value);
  };

  const get = async(key) => {
    return await storage.get(key);
  };

  const remove = async(key) => {
    return await storage.remove(key);
  };

  return {
    get,
    set,
    remove,
  };
}
