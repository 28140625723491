import { defineStore } from 'pinia';
import useApi from "@/composables/useApi";
import {CostItem, CostResponse} from "@/constants/costTypes";

export const useCostStore = defineStore('costStore', {
  state: () => ({
    costs: [] as CostItem[],
  }),
  actions: {
    async fetchCostTypes() {
      const { apiGet } = useApi();
      this.loading = true;
      const res = await apiGet<CostResponse>('/timereport/costtypes');

      if (res && res.result) {
        this.costs = res.result;
      }
    },
  },
  getters: {
    getCostById: (state) => (id: number) => {
      return state.costs.find(cost => cost.id === id);
    },
  }
});
