import { defineStore } from 'pinia';
import useApi from "@/composables/useApi";
import {Office, OfficeResponse} from "@/constants/officesTypes";

export const useOfficesStore = defineStore('officeStore', {
  state: () => ({
    offices: [] as Office[],
    loading: true,
  }),
  actions: {
    async fetchOffices() {
      const { apiGet } = useApi();
      this.loading = true;
      const res = await apiGet<OfficeResponse>('/offices/me');

      if (res && res.result) {
        this.offices = res.result;
        this.loading = false;
      }
    },
  },
});
