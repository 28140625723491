<template>
  <qrcode-vue :value="code" :level="level" :render-as="renderAs" :size="240" />
</template>
<script setup lang="ts">
import { ref } from 'vue'
import QrcodeVue, { Level, RenderAs } from 'qrcode.vue'
defineProps<{
  code: string,
}>()

const level = ref<Level>('M')
const renderAs = ref<RenderAs>('svg')
</script>
