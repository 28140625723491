import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';

import { IonicVue } from '@ionic/vue';
import debounce from 'lodash.debounce';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* Theme variables */
import './styles/tailwind.css';
import './theme/variables.css';

/* @import '@ionic/vue/css/palettes/dark.always.css'; */
import '@ionic/vue/css/palettes/dark.class.css';
// import '@ionic/vue/css/palettes/dark.system.css';

import 'swiper/css';

import { createPinia } from "pinia";

const pinia = createPinia();

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(pinia)
  .use(router);

Sentry.init({
    app,
    environment: import.meta.env.VITE_BUILD_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracePropagationTargets: ['localhost'],
    // Performance Monitoring
    tracesSampleRate: 0.02, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.02, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
// Forward the init method from @sentry/vue
  SentryVue.init
);

router.isReady().then(() => {
  app.mount('#app');
});
