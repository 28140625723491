import { defineStore } from 'pinia';
import router from "@/router";
import useLocalStorage from "@/composables/useLocalStorage";
import useApi from "@/composables/useApi";
import {User, UserResponse} from "@/constants/userTypes";
import {useMessageStore} from "@/stores/messages";
import {useOfficesStore} from "@/stores/offices";
import {usePayslipsStore} from "@/stores/payslips";
import {useCostStore} from "@/stores/costs";
import {PushNotifications, Token} from "@capacitor/push-notifications";
import {isPlatform} from "@ionic/vue";
import {APINotification, LaravelNotification} from "@/constants/pushNotificationTypes";
import { Badge } from '@capawesome/capacitor-badge';

export const useUserStore = defineStore('userStore', {
  state: () => ({
    accountToken: null as string | null,
    userFetchedAt: null as Date | null,
    userData: null as User | null,
    loading: false,
    notifications: [] as LaravelNotification[],
    messageNotifEnabled: false,
    workOrderNotifEnabled: false,
    payslipNotifEnabled: false,
    myWorkOrderNotifEnabled: false,
    pushToken: '',
  }),
  actions: {
    async login({ accessToken, accessTokenExpiresAt }: { accessToken: string, accessTokenExpiresAt: number }) {
      const storage = await useLocalStorage();
      await storage.set('accountToken', accessToken);
      await storage.set('accountTokenExpiresAt', accessTokenExpiresAt);
      await this.fetchUser();
      this.loading = false;
      await router.push('/tabs/home');
    },
    async manualLogin({ email, password }: { email: string, password: string }) {
      const { apiPost, handleApiError } = useApi();
      this.loading = true;
      const clientId = import.meta.env.VITE_CLIENT_ID;
      const clientSecret = import.meta.env.VITE_CLIENT_SECRET;
      console.log(clientId, clientSecret);

      const res = await apiPost<{ access_token: string; expires_in: number }>('/oauth/token', {
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'password',
        username: email,
        password: password,
      }, true);

      if (res && res.access_token) {
        await this.login({
          accessToken: res.access_token,
          accessTokenExpiresAt: Date.now() + res.expires_in,
        });
        this.loading = false;
        return true;
      } else {
        await handleApiError();
        this.loading = false;
        return false;
      }

    },
    async fetchUser() {
      const { apiGet } = useApi();
      const { fetchMessages } = useMessageStore();
      const { fetchOffices } = useOfficesStore();
      const { fetchPayslips } = usePayslipsStore();
      const { fetchCostTypes } = useCostStore();
      const res = await apiGet<UserResponse>('/employee/me');
      this.userFetchedAt = Date.now();

      if (res && res.result) {
        console.log(res.result);
        this.userData = res.result;
        await fetchMessages();
        await fetchOffices();
        await fetchPayslips();
        await fetchCostTypes();
        await this.getNotificationSettings();

        if (isPlatform('ios') || isPlatform('android')) {
          await this.registerPushToken();
          await this.getPushNotifications();
        }
      }
    },
    async shouldFetchUser() {
      if (Date.now() > (this.userFetchedAt + (1000 * 60 * 60))) {
        await this.fetchUser();
      }
    },
    async updateUser(user: {
      first_name?: string,
      last_name?: string,
      email?: string,
      phone?: string,
      address?: string,
      postal_code?: string,
      city?: string,
    }) {
      const { apiPut, handleApiError } = useApi();
      const res = await apiPut<UserResponse>('/employee/me', user);
      if (res && res.result) {
        this.userData = res.result;
        return true;
      } else {
        await handleApiError();
        return false;
      }
    },
    async savePushToken(token: string) {
      this.pushToken = token;
    },
    async registerPushToken() {
      const { apiPut, handleApiError } = useApi();
      const res = await apiPut<Token>('/v2/push/token', { token: this.pushToken });

      if (!res) {
        await handleApiError();
      }
    },
    async getPushNotifications() {
      const { apiGet } = useApi();
      const res = await apiGet<{ result: LaravelNotification[] }>('/v2/push/unread');

      if (res) {
        this.notifications = res.result;

        await Badge.set({ count: res.result.length });
      }
    },
    async clearNotification({ resourceType, resourceId }: { resourceType: number, resourceId?: number }) {
      const { apiPost } = useApi();

      const notifications = await PushNotifications.getDeliveredNotifications() as { notifications: APINotification[] };
      const notificationsToRemove = notifications.notifications.filter(n => n.data.resource_type === resourceType.toString());

      await PushNotifications.removeDeliveredNotifications({ notifications: notificationsToRemove });
      await apiPost('/v2/push/markasread', { resource_type: resourceType, resource_id: resourceId });
      await this.getPushNotifications();
    },
    async getNotificationSettings() {
      const { apiGet } = useApi();
      const res = await apiGet<{ result: { type: number, enabled: number }[] }>('/employee/me/notificationsettings');
      if (res) {
        this.setNotificationSettings(res.result);
      }
    },
    async updateNotificationSettings(setting: { type: number, enabled: boolean }) {
      const { apiPut } = useApi();
      const res = await apiPut<{ result: { type: number, enabled: number }[] }>(`/employee/me/notificationsettings/${setting.type}`, { enabled: setting.enabled });
      if (res) {
        this.setNotificationSettings(res.result);
      }
    },
    setNotificationSettings(settings: { type: number, enabled: number }[]) {
      this.messageNotifEnabled = !!settings?.find(setting => setting.type === 1).enabled;
      this.workOrderNotifEnabled = !!settings?.find(setting => setting.type === 2).enabled
      this.payslipNotifEnabled = !!settings?.find(setting => setting.type === 3).enabled
      this.myWorkOrderNotifEnabled = !!settings?.find(setting => setting.type === 4).enabled
    },
    async logOut() {
      const storage = await useLocalStorage();
      await storage.remove('accountToken');
      await storage.remove('accountTokenExpiresAt');
      this.userData = null;

      await router.replace('/auth/');
    }
  },
  getters: {
    user(state): User | null {
      return state.userData;
    }
  }
});
